<!-- eslint-disable vue/no-v-html -->
<template>
  <IAmOverlay :loading="loading">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-card
        id="document-edit"
        no-body
      >
        <b-card-title class="m-1">
          <div class="d-flex-center justify-content-between">
            <div
              v-if="readonly"
              class="d-flex-center"
            >
              <feather-icon
                icon="FolderMinusIcon"
                size="24"
                class="mx-50"
              />
              <span>{{ $t('document.docDetail') }}</span>
            </div>

            <div
              v-else
              class="d-flex-center"
            >
              <feather-icon
                v-if="!isMobileView"
                icon="EditIcon"
                size="24"
                class="mx-50"
              />
              <span :class="isMobileView ? 'text-nowrap' : ''">{{ $t('document.docEdit') }}</span>
            </div>
            <div
              v-if="canModify"
              :class="isMobileView ? 'd-flex flex-wrap justify-content-end' : ''"
            >
              <b-button
                v-if="!readonly"
                variant="danger"
                class="mx-md-50 py-50 py-md-1 mt-50 mt-md-0"
                @click="confirmDelete"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="mr-25"
                />
                {{ $t('delete') }}
              </b-button>

              <b-button
                v-if="readonly"
                variant="info"
                class="ml-50 py-50 py-md-1 mt-50 mt-md-0"
                @click="handleEdit"
              >
                <span class="">{{ $t('edit') }}</span>
              </b-button>
              <b-button
                v-else
                variant="outline-info"
                class="ml-50 py-50 py-md-1 mt-50 mt-md-0"
                @click="handleCancelEdit"
              >
                <span class="">{{ $t('cancelEdit') }}</span>
              </b-button>
            </div>
          </div>
        </b-card-title>

        <!-- SECTION Form -->
        <div
          v-if="documentDataToEdit"
          class="mb-1 mx-1"
        >
          <b-form @submit.prevent="handleSubmit(nextHandle)">
            <b-card
              header-tag="header"
              border-variant="info"
              header-bg-variant="light-info"
              header-class="py-1"
              class="border"
              no-body
            >
              <template #header>
                <h6 class="m-0">
                  {{ $t('document.infoDocs') }}
                </h6>
              </template>
              <b-card-body class="px-1 py-0">
                <b-row class="mt-1">
                  <!-- ANCHOR Document Name -->
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('document.docsName')"
                      rules="required"
                    >
                      <b-form-group label-for="documentName">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.docsName') }}
                            <span
                              v-if="!readonly"
                              class="text-danger"
                            >(*)</span>
                          </div>
                        </template>
                        <b-form-input
                          id="documentName"
                          v-model="documentDataToEdit.name"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :disabled="readonly"
                          lazy-formatter
                          :formatter="trimInput"
                          :placeholder="$t('document.ph.docsName')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Document Name -->
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('document.order')"
                      rules=""
                    >
                      <b-form-group label-for="documentOrder">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.order') }}
                          </div>
                        </template>
                        <b-form-input
                          id="documentOrder"
                          v-model="documentDataToEdit.order"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :disabled="readonly"
                          lazy-formatter
                          :formatter="trimInput"
                          type="number"
                          :placeholder="$t('document.order')"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ANCHOR Document folder - required -->
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('document.Folder')"
                      rules="required"
                    >
                      <b-form-group label-for="documentFolder">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.Folder') }}
                            <span
                              v-if="!readonly"
                              class="text-danger"
                            >(*)</span>
                          </div>
                        </template>
                        <v-select
                          v-model="documentDataToEdit.parent"
                          :state="getValidationState(validationContext) === false ? false : null"
                          :options="folderList"
                          :reduce="(val) => val.id"
                          :clearable="false"
                          searchable
                          label="id"
                          :disabled="readonly"
                          :filterable="false"
                          :loading="loading"
                          placeholder="Please Select"
                          @open="openFolder"
                          @search="searchFolder"
                        >
                          <template #selected-option="{ name }">
                            <div class="d-flex align-items-center">
                              <span class="d-block text-truncate">
                                {{ name }}
                              </span>
                            </div>
                          </template>
                          <template #option="{ name }">
                            <div class="d-flex align-items-center">
                              <span class="font-weight-bold d-block text-nowrap">
                                {{ name }}
                              </span>
                            </div>
                          </template>
                          <template #spinner>
                            <div
                              v-if="loading"
                              style="border-left-color: rgba(88, 151, 251, 0.71)"
                              class="vs__spinner"
                            />
                          </template>
                          <template #no-options>
                            Loading...
                          </template>
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- ANCHOR Description -->
                  <b-col
                    cols="12"
                    class="quill-img-max-width"
                  >
                    <validation-provider
                      #default="validationContext"
                      :name="$t('document.description')"
                      rules=""
                    >
                      <b-form-group label-for="description">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.description') }}
                          </div>
                        </template>
                        <div v-if="readonly">
                          <div v-html="documentDataToEdit.description" />
                        </div>
                        <div v-else>
                          <quill-editor
                            ref="quillRef"
                            v-model="documentDataToEdit.description"
                            :options="editorOption"
                            :disabled="readonly"
                          />
                        </div>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- ANCHOR file -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="File"
                      :rules="`size:${MAX_SIZE_TO_UPLOAD_FILE}`"
                    >
                      <b-form-group label-for="file">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.file') }}
                            <em v-if="!readonly">(Dung lượng tối đa: {{ sizeFormatter(MAX_SIZE_TO_UPLOAD_FILE) }})</em>
                          <!-- <span class="text-danger">(*)</span> -->
                          </div>
                        </template>
                        <div v-if="readonly">
                          <div v-if="!(documentDataToEdit.files && documentDataToEdit.files.length)">
                            <em>Không có tệp đính kèm.</em>
                          </div>
                          <div v-else>
                            <div
                              v-for="(file, index) in documentDataToEdit.files"
                              :key="index"
                            >
                              {{ index + 1 }}.
                              <b-link
                                v-b-tooltip.hover.v-info.window
                                :title="file.file"
                                @click="confirmDownloadFile(file)"
                              >
                                <span>{{ shortenFileName(file.file, 50) }}</span>
                              </b-link>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div>
                            <div
                              v-for="(fileDocument, indexDocument) in fileDocuments"
                              :key="indexDocument"
                              class="font-weight-bold d-flex-center justify-content-start"
                            >
                              <span v-if="fileDocument.name && fileDocument.size">{{ indexDocument + 1 }}. {{ fileDocument.name }} ({{ sizeFormatter(fileDocument.size) }})</span>
                              <span v-else>{{ indexDocument + 1 }}. <b-link
                                v-b-tooltip.hover.v-info.window
                                :title="fileDocument.file"
                                @click="confirmDownloadFile(fileDocument)"
                              >
                                <span>{{ shortenFileName(fileDocument.file, 50) }}</span>
                              </b-link>
                              </span>
                              <b-button
                                variant="flat-danger"
                                class="p-50 ml-25"
                                @click="handleRemoveFileAdded(indexDocument)"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  size="14"
                                />
                              </b-button>
                            </div>
                          </div>
                          <div class="d-flex mt-25">
                            <b-form-file
                              id="file"
                              v-model="newFileToAdd"
                              multiple
                              class="py-0"
                              accept="*"
                              :state="getValidationState(validationContext) === false ? false : null"
                              style="z-index: 0"
                            />
                          </div>
                        </div>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <!-- !SECTION -->

            <!-- SECTION Document Details -->
            <b-card
              v-if="canModify"
              header-tag="header"
              border-variant="info"
              header-bg-variant="light-info"
              header-class="py-1"
              class="border mt-1"
              no-body
            >
              <template #header>
                <h6 class="m-0">
                  {{ $t('document.docsStatus') }}
                </h6>
              </template>

              <b-card-body class="px-1 py-0">
                <b-row class="mt-1">
                  <!-- ANCHOR Status - required -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider>
                      <b-form-group label-for="isShow">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.isShowTitle') }}
                          </div>
                        </template>
                        <div class="d-flex">
                          <b-form-checkbox
                            id="isShow"
                            v-model="documentDataToEdit.isShow"
                            switch
                            :disabled="readonly"
                          />
                          <span>{{ $t('document.forF3') }}</span>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider>
                      <b-form-group label-for="isActive">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.isActiveTitle') }}
                          </div>
                        </template>
                        <div class="d-flex">
                          <b-form-checkbox
                            id="isActive"
                            v-model="documentDataToEdit.isActive"
                            switch
                            :disabled="readonly"
                          />
                          <span>{{ $t('document.publish') }}</span>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider>
                      <b-form-group label-for="isPin">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.isPinTitle') }}
                          </div>
                        </template>
                        <div class="d-flex">
                          <b-form-checkbox
                            id="isPin"
                            v-model="documentDataToEdit.isPin"
                            switch
                            :disabled="readonly"
                          />
                          <span>{{ $t('document.isPinTitle') }}</span>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="documentDataToEdit.isPin"
                    cols="12"
                    md="6"
                  >
                    <validation-provider :name="$t('document.priorityPin.title')">
                      <b-form-group label-for="priorityPinTitle">
                        <template #label>
                          <div class="text-heading-5">
                            {{ $t('document.priorityPin.title') }}
                          </div>
                        </template>
                        <v-select
                          id="priorityPinTitle"
                          v-model="documentDataToEdit.priorityPin"
                          :options="PRIORITY_PIN"
                          :reduce="(val) => val.key"
                          :clearable="false"
                          label="key"
                          :disabled="readonly"
                          :loading="loading"
                          :placeholder="$t('document.ph.folder')"
                        >
                          <template #selected-option="data">
                            <span class="font-weight-bold d-block text-nowrap">
                              {{ $t(`document.priorityPin.${data.key}`) }}
                            </span>
                          </template>
                          <template #option="data">
                            <span class="font-weight-bold d-block text-nowrap">
                              {{ $t(`document.priorityPin.${data.key}`) }}
                            </span>
                          </template>
                        </v-select>
                        <small class="text-danger">
                          {{ $te(`document.priorityPin.desc${documentDataToEdit.priorityPin}`)
                            ? $t(`document.priorityPin.desc${documentDataToEdit.priorityPin}`) : '' }}
                        </small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

            <!-- ANCHOR Action Buttons -->
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-center"
              >
                <!-- Back button -->
                <b-button
                  variant="outline-danger"
                  class="mr-1 px-3"
                  @click="backHandle"
                >
                  {{ $t('back') }}
                </b-button>

                <!-- Save button -->
                <b-button
                  v-if="canModify"
                  variant="gradient"
                  class="ml-1 px-3"
                  :disabled="readonly || loading"
                  @click="submitHandle"
                >
                  <div v-if="loading">
                    <b-spinner
                      v-if="loading"
                      small
                    />
                    {{ $t('saving') }}
                  </div>
                  <span v-else>
                    {{ $t('save') }}
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- !SECTION -->
      </b-card>
    </validation-observer>
  </IAmOverlay>
</template>

<script>
import {
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BSpinner, BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
import {
  onMounted, onUnmounted, ref, watch,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import cloneDeep from 'lodash/cloneDeep'

import { PRIORITY_PIN } from '@/constants/document'
import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'
import {
  typeDocumentOptions,
  statusDocumentOptions,
} from '@/constants/selectOptions'
import router from '@/router'

import { getDifference } from '@core/utils/utils'
import { dateTime, sizeFormatter } from '@core/utils/filter'
import { trimInput } from '@core/comp-functions/forms/formatter-input'
import formValidation from '@core/comp-functions/forms/form-validation'

import { required } from '@validations'

import documentStoreModule from '../documentStoreModule'
import useDocumentHandle from '../useDocumentHandle'

export default {
  components: {
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    IAmOverlay,
    BSpinner,
    vSelect,
    quillEditor,
    BLink,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  watch: {
    $route(to) {
      this.fetchDocument(to.params.id)
    },
  },
  setup() {
    // Setup Quill fontSize
    const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px']
    const sizeStyle = Quill.import('attributors/style/size')
    sizeStyle.whitelist = [...fontSizeArr]
    Quill.register(sizeStyle, true)

    const fontStyleArr = ['Lato', 'arial', 'tinos', 'inconsolata', 'roboto', 'montserrat', 'sofia']
    const fontStyle = Quill.import('formats/font')
    fontStyle.whitelist = fontStyleArr
    Quill.register(fontStyle, true)

    const editorOption = ref({
      syntax: false,
      modules: {
        toolbar: [
          [{ font: fontStyleArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ color: [] }, { background: [] }],
          ['blockquote', 'code-block', 'link'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          ['image', 'video'],
        ],
      },
      placeholder: 'Vui lòng nhập nội dung...',
    })
    const MAX_SIZE_TO_UPLOAD_FILE = process.env.VUE_APP_MAX_SIZE_FOR_UPLOAD_FILE
    const DOCUMENT_APP_STORE_MODULE_NAME = 'app-documents'

    // Register module
    if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
    })

    const documentData = ref(null)
    const documentDataToEdit = ref(null)

    const readonly = ref(true)

    const resetDocumentDataToEdit = () => {
      documentDataToEdit.value = cloneDeep(documentData.value)
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetDocumentDataToEdit)
    const {
      updateDocument,
      openFolder,
      searchFolder,
      folderList,
      getDocumentById,
      deleteDocument,
      canModify,
      shortenFileName,
      confirmDownloadFile,
      handleUploadFiles,
      dataURLtoFile,
    } = useDocumentHandle()

    const fileDocuments = ref([])
    const newFileToAdd = ref()
    const loading = ref(false)
    onMounted(() => {
      loading.value = true
    })

    function fetchDocument(id) {
      loading.value = true
      getDocumentById(id)
        .then(response => {
          documentData.value = response.data
          documentDataToEdit.value = cloneDeep(documentData.value)
          fileDocuments.value = documentDataToEdit.value.files
        })
        .catch(error => {
          if (error.response.status === 404) {
            documentData.value = undefined
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    fetchDocument(router.currentRoute.params.id)

    function handleEdit() {
      readonly.value = false
    }

    function handleCancelEdit() {
      resetForm()
      readonly.value = true
    }

    watch(() => newFileToAdd, () => {
      if (newFileToAdd.value) {
        fileDocuments.value.push(...newFileToAdd.value)
        newFileToAdd.value = null
      }
    }, { deep: true })

    const quillRef = ref()

    const submitHandle = async () => {
      loading.value = true
      try {
        let content = quillRef.value.value
        const imageArr = quillRef.value.quill?.editor?.delta?.ops
          .filter(item => item?.insert?.image && item?.insert?.image.startsWith('data:image'))
          .map(item => item?.insert?.image)
        if (imageArr.length) {
          await Promise.all(imageArr.map(async (img, index) => {
            const file = dataURLtoFile(img, index)
            const uploadedFile = await handleUploadFiles([file])
            content = content.replace(img, uploadedFile[0]?.url)
          }))
        }
        documentDataToEdit.value.description = content

        const files = fileDocuments.value
        if (files) {
          documentDataToEdit.value.files = await handleUploadFiles(files)
        }
        documentDataToEdit.value.order = documentDataToEdit.value?.order ? Number(documentDataToEdit.value?.order) : null

        const dataToUpdate = getDifference(documentDataToEdit.value, documentData.value)
        const parentId = documentData.value.parent.id
        await updateDocument(
          documentData.value,
          dataToUpdate,
          () => {
            store.dispatch('globalConfig/getAllPins')
            router.push({ name: 'apps-documents-list', params: { id: parentId } })
          },
        )
      } catch (error) {
        console.error({ error })
      } finally {
        loading.value = false
      }
    }

    function backHandle() {
      this.$router.go(-1)
    }

    function confirmDelete() {
      const item = documentDataToEdit.value
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `<span class='text-break'>Bạn có muốn xoá tài liệu <em class='font-weight-bolder text-wrap'>${item?.name}</em> ?</span>` } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: 'XOÁ TÀI LIỆU',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Xoá',
          cancelTitle: 'Huỷ',
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deleteDocument(item, () => {
              router.push({ name: 'apps-documents-list', params: { id: documentDataToEdit.value.parent.id } })
            })
          }
        })
    }
    function handleRemoveFileAdded(index) {
      fileDocuments.value.splice(index, 1)
    }

    return {
      documentData,
      documentDataToEdit,
      submitHandle,
      refFormObserver,
      getValidationState,

      // select options
      typeDocumentOptions,
      statusDocumentOptions,
      openFolder,
      searchFolder,
      folderList,
      fileDocuments,
      newFileToAdd,
      handleRemoveFileAdded,

      // Validations
      required,

      backHandle,
      handleEdit,
      handleCancelEdit,

      readonly,
      trimInput,
      resetForm,
      dateTime,

      loading,
      confirmDelete,
      canModify,

      shortenFileName,
      confirmDownloadFile,
      sizeFormatter,
      MAX_SIZE_TO_UPLOAD_FILE,
      editorOption,
      quillRef,
      PRIORITY_PIN,
      fetchDocument,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
#document-edit::v-deep {
  p {
    line-height: unset !important;
  }

  .quill-img-max-width img {
    max-width: 100%;
  }
}
</style>
